import React, { FC, ReactElement, useState } from 'react';
import styles from './PccVideo.module.css';
import IPccVideoProps from '../../domain/interfaces/components/IPccVideoProps';
import HoverVideoPlayer from 'react-hover-video-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';

const PccVideo: FC<IPccVideoProps> = React.memo((props): ReactElement => {
  const [isPlay, setIsPlay] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const isMovieFromYouTube =
    props.videoPath.includes('youtu') || props.videoPath.includes('youtube');

  const handleMouseEnter = () => {
    setIsPlay(true);
  };

  const handleMouseLeave = () => {
    setIsPlay(false);
  };

  return isMovieFromYouTube ? (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ReactPlayer
        playing={isPlay}
        height={200}
        width={'100%'}
        muted
        url={props.videoPath}
        config={{
          youtube: {
            playerVars: { showinfo: 1 }
          },
          file: {
            attributes: {
              controlsList: 'nodownload' //<- this is the important bit
            }
          }
        }}
      />
    </div>
  ) : (
    <>
      <HoverVideoPlayer
        videoSrc={props.videoPath}
        className={props.className}
        playbackRangeStart={10}
        muted
        controls={false}
        style={{ minHeight: 200 }}
        crossOrigin="anonymous"
        pausedOverlay={
          <div className={styles.overlayWrapper}>
            <FontAwesomeIcon
              className={styles['icon-play']}
              icon={faPlayCircle}
              size="3x"
              color="#fff"
            />
            <img
              className={styles['image-placeholder']}
              src="/img/placeholder.jpg"
              alt=""
            />
          </div>
        }
        loadingOverlay={
          <div className="loading-overlay">
            <div className="loading-spinner" />
          </div>
        }
        hoverOverlay={
          <div
            className="d-flex w-100 h-100"
            onClick={() => setIsVideoPlaying(!isVideoPlaying)}
          ></div>
        }
        focused={isVideoPlaying}
      />
    </>
  );
});

export default PccVideo;
